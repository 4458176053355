import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../context/DataContext';
import { useChannel } from '../../hooks/useChannel';
import { useVidconChannel } from '../../hooks/useVideoControlChannel';

import './styles.scss';

function Top() {
  const [data, setData] = useContext(DataContext);
  const [videoStatus, setVideoStatus] = useState({ pause: false });
  const TIME = 3 * 60 * 1000; // 3 minutes to milliseconds
  const channel = useChannel();
  const vcChannel = useVidconChannel();
  let timer;
  vcChannel.getMessage(setVideoStatus);
  const handleOnPlay = () => {
    console.log('pausing videotimer');
    clearTimeout(timer);
  };
  const handleLoaded = (e) => {
    e.target.play();
  };
  const handleEnded = () => {
    console.log('Resetting App');
    channel.sendMessage({
      category: 'all',
      project: '',
      detailProject: '',
      mediaName: '',
      categoryMarker: '',
      numberProjectsByCountry: {},
      isAppActive: false,
      isVideoPlaying: false,
    });
  };
  const startIdleTimer = () => {
    console.log('starting videotimer...');
    if (data.isAppActive)
      timer = setTimeout(() => {
        console.log('start video');
        channel.sendMessage({
          category: 'all',
          project: '',
          detailProject: '',
          mediaName: '',
          categoryMarker: '',
          numberProjectsByCountry: {},
          isAppActive: false,
          isVideoPlaying: false,
        });
      }, TIME); // add less time for testing
  };

  /* eslint-disable */
  useEffect(() => {
    if (data.isAppActive && !data.isVideoPlaying) {
      startIdleTimer();
      return () => clearTimeout(timer);
    }
    console.log(videoStatus);
    if (videoStatus.pause) {
      document.querySelector('video').pause();
      startIdleTimer();
    } else if (!videoStatus.pause && data.isAppActive) {
      document.querySelector('video').play();
      handleOnPlay();
    }
  }, [data, videoStatus]);
  /* eslint-enable */

  if (data.isAppActive && data.mediaName && !data.isVideoPlaying) {
    return (
      <figure className="top-screen__media-container">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/${data.mediaName}`}
          alt=""
        />
      </figure>
    );
  } else if (data.isAppActive && data.mediaName && data.isVideoPlaying) {
    return (
      <div className="top-screen__media-container">
        <video autoPlay onPlay={handleOnPlay} onEnded={handleEnded}>
          <source
            key={'media-video'}
            src={`${process.env.PUBLIC_URL}/assets/videos/${data.mediaName}`}
            type="video/mp4"
          />
          <track
            src={`${
              process.env.PUBLIC_URL
            }/assets/videos/${data.mediaName.substring(
              0,
              data.mediaName.length - 4
            )}.vtt`}
            kind="subtitles"
            label="Deutsch"
            default
          />
        </video>
      </div>
    );
  } else {
    return (
      // To watch the video when the app runs for the first time
      <div className="top-screen__loop-video-wrapper">
        <video key={'starting-loop'} autoPlay loop onLoadedData={handleLoaded}>
          <source
            src={`${process.env.PUBLIC_URL}/assets/videos/video-loop.mp4`}
            type="video/mp4"
          />
        </video>
      </div>
    );
  }
}

export default Top;
