import React, { useState, createContext } from 'react';
import { useChannel } from '../hooks/useChannel';

export const DataContext = createContext();

export function DataProvider(props) {
  const [data, setData] = useState({
    category: 'all',
    project: '',
    detailProject: '',
    mediaName: '',
    categoryMarker: '',
    numberProjectsByCountry: {},
    isAppActive: false,
    isVideoPlaying: false,
  });

  const channel = useChannel();
  channel.getMessage(setData);

  return (
    <DataContext.Provider value={[data, setData]}>
      {props.children}
    </DataContext.Provider>
  );
}
