import React, { useContext } from 'react';

import { ReactComponent as MarkerSVG } from '../../assets/map/marker.svg';

import './styles.scss';

import { MapContext } from '../../context/MapContext';

function Marker(props) {
  const {
    id,
    categoryId,
    projectId,
    projectLand,
    projectCountry,
    projectNumbers,
    projectThumbnail,
    positionProject,
    defaultPosition,
    handleClick,
  } = props;

  const [map, setMap] = useContext(MapContext);

  let stylePosition = {};
  let left;
  let top;

  if (Object.keys(defaultPosition).length > 0) {
    left = `${defaultPosition.x ? defaultPosition.x : 0}%`;
    top = `${defaultPosition.y ? defaultPosition.y : 0}%`;

    // Move markers when there is more than one project in the same country
    if (map.markerCountry && projectCountry === map.markerCountry) {
      left = `${positionProject.x ? positionProject.x : defaultPosition}%`;
      top = `${positionProject.y ? positionProject.y : defaultPosition}%`;
    }
  }

  if (Object.keys(defaultPosition).length > 0) {
    stylePosition = { left, top };
  }

  return (
    <div
      id={id}
      className={`marker${
        projectThumbnail ? ' marker-image' : ''
      } ${categoryId}`}
      data-category={categoryId}
      data-project={projectId}
      data-land={projectLand}
      data-country={projectCountry}
      data-more-than-one={projectNumbers ? true : false}
      data-show-other-markers={false}
      onClick={handleClick}
      style={stylePosition}
    >
      <div className="marker__wrapper" data-numbers={projectNumbers}>
        {!projectThumbnail ? (
          <MarkerSVG />
        ) : (
          <figure className="marker__image-container">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/${projectThumbnail}`}
              alt=""
            />
          </figure>
        )}
      </div>
    </div>
  );
}

export default Marker;
