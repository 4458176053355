import React from 'react';

import './styles.scss';

function ProjectItem(props) {
  const { id, name, thumbnail, handleClick, isSelected } = props;
  const isSelectedClass = isSelected ? ' project-item--selected' : '';

  return (
    <div
      id={`pi-${id}`}
      className={`project-item${isSelectedClass}`}
      data-project={id}
      onClick={handleClick}
    >
      {thumbnail && (
        <img
          className="project-item__img"
          src={`${process.env.PUBLIC_URL}/assets/images/${thumbnail}`}
          alt={name}
        />
      )}
      <p className="project-item__name">{name}</p>
    </div>
  );
}

export default ProjectItem;
