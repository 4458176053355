import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { TranslationProvider } from './context/TranslationContext';
import { DataProvider } from './context/DataContext';
import { MapProvider } from './context/MapContext';
import Top from './pages/Top';
import Below from './pages/Below';
import Home from './pages/Home';

import './app.scss';

function App() {
  return (
    <div className="app">
      <TranslationProvider>
        <DataProvider>
          <MapProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/top" element={<Top />}></Route>
                <Route path="/below" element={<Below />}></Route>
              </Routes>
            </BrowserRouter>
          </MapProvider>
        </DataProvider>
      </TranslationProvider>
    </div>
  );
}

export default App;
