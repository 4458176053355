import React, { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import { TranslationContext } from '../../context/TranslationContext';
import { useChannel } from '../../hooks/useChannel';
import ProjectItem from '../ProjectItem';

import './styles.scss';

function MoreProjects() {
  const channel = useChannel();
  const [translation, setTranslation] = useContext(TranslationContext);
  const [data, setData] = useContext(DataContext);
  const NUMBER_OF_PROJECTS = 4;
  let selectedProject = [];
  let projectsDifferentLand = [];
  let shuffledProjects = [];
  let projectsList = [];
  let targetProject = [];

  // Get selected project
  selectedProject = translation.data.projects.filter(
    (project) => project.id === data.project
  );

  if (selectedProject.length > 0) {
    // Projects from a different land than the selected project
    projectsDifferentLand = translation.data.projects.filter(
      (project) => project.land !== selectedProject[0].land
    );
    // Get random projects
    shuffledProjects = projectsDifferentLand.sort(() => Math.random() - 0.5);
  }

  function handleClick(e) {
    targetProject = translation.data.projects.filter(
      (project) => project.id === e.target.dataset.project
    );

    channel.sendMessage({
      ...data,
      mediaName: targetProject[0]?.thumbnail,
      detailProject: e.target.dataset.project,
      project: e.target.dataset.project,
      isAppActive: true,
      isVideoPlaying: false
    });
  }

  projectsList = shuffledProjects
    .slice(0, NUMBER_OF_PROJECTS)
    .map((project) => {
      if (project) {
        return (
          <ProjectItem
            key={project.id}
            id={project.id}
            name={project.name}
            thumbnail={project?.thumbnail}
            handleClick={handleClick}
          />
        );
      }
    });

  if (!data.detailProject) {
    return;
  }

  return (
    <div className="more-projects">
      <h2 className="more-projects__title">{translation.data.moreProjects}</h2>
      <div className="more-projects__projects">{projectsList}</div>
    </div>
  );
}

export default MoreProjects;
