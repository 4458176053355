import React, { useContext } from 'react';

import { DataContext } from '../../context/DataContext';
import { useChannel } from '../../hooks/useChannel';

import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import './styles.scss';
import { useVidconChannel } from '../../hooks/useVideoControlChannel';

function ProjectDetailSlider(props) {
  const { project } = props;
  const channel = useChannel();
  const vcChannel = useVidconChannel();
  const [data, setData] = useContext(DataContext);
  let sliderClass = '';
  let media = [];
  let mediaItems = [...project.media.images, ...project.media.videos];
  function handleClick(e) {
    channel.sendMessage({
      ...data,
      mediaName: e.target.dataset.mediaName,
      isAppActive: true,
      isVideoPlaying: false,
    });
  }
  function handleVideoClick(e) {
    if (e.target.classList.contains('is-playing')) {
      vcChannel.sendMessage({ pause: true });
      e.target.classList.remove('is-playing');
    } else {
      vcChannel.sendMessage({ pause: false });
      e.target.classList.add('is-playing');
    }
    channel.sendMessage({
      ...data,
      mediaName: e.target.dataset.mediaName,
      isAppActive: true,
      isVideoPlaying: true,
    });
  }

  switch (mediaItems.length) {
    case 1:
      sliderClass = ' one-slide';
      break;
    case 2:
      sliderClass = ' two-slides';
      break;
    default:
      break;
  }

  media = mediaItems.map((item, index) => (
    <SwiperSlide key={index}>
      {item.split('.').pop() === 'mp4' ? (
        <div
          className={
            'project-detail-slider__video-container' +
            (data.isVideoPlaying ? ' is-playing' : '')
          }
          onClick={handleVideoClick}
          data-media-name={item}
        >
          <video
            data-media-name={item}
            src={`${process.env.PUBLIC_URL}/assets/videos/${item}`}
          />
        </div>
      ) : (
        <figure
          className="project-detail-slider__image-container"
          onClick={handleClick}
          data-media-name={item}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/${item}`} alt="" />
        </figure>
      )}
    </SwiperSlide>
  ));

  return (
    <div className={`project-detail-slider${sliderClass}`}>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={project.media.images.length === 1 ? 0 : 30}
        slidesPerView={3}
        navigation
        pagination={{ clickable: true }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {media}
      </Swiper>
    </div>
  );
}

export default ProjectDetailSlider;
