import React, { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import { TranslationContext } from '../../context/TranslationContext';
import CategoryItem from '../CategoryItem';

import './styles.scss';

function CategoryList() {
  const [translation, setTranslation] = useContext(TranslationContext);
  const [data, setdata] = useContext(DataContext);
  const isActiveClass = data.project ? '' : ' category-list--active';

  const listItems = translation.data.categories.map((item, index) => {
    let isActive = data.category === item.id ? true : false;
    return (
      <CategoryItem
        key={item.id}
        id={item.id}
        name={item.name}
        isActive={isActive}
      />
    );
  });

  return <ul className={`category-list${isActiveClass}`}>{listItems}</ul>;
}

export default CategoryList;
