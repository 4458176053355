import React from 'react';
import LanguageSwitcher from '../LanguageSwitcher';
import GoBackButton from '../GoBackButton';

import logo from '../../assets/logo@2x.png';

import './styles.scss';

function Header() {
  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__logo-container">
          <img src={logo} alt="TUI Care Foundation Logo" />
        </div>
        <LanguageSwitcher />
        <GoBackButton />
      </div>
    </header>
  );
}

export default Header;
