export const MOVE_MARKER_CLASS = 'move-marker';

export function moveMap(event, mapZoom = 1, markersZoom = 1) {
  // There is an event if you click on a marker
  if (event) {
    let x =
      event.target.parentElement.parentElement.offsetWidth / 2 -
      event.target.offsetLeft -
      event.target.offsetWidth / 2;

    let y =
      event.target.parentElement.offsetHeight / 2 -
      event.target.offsetTop -
      event.target.offsetHeight / 2;

    // Zoom - Map
    event.target.parentElement.style.transform = `scale(${mapZoom}) translate(${x}px, ${y}px)`;
    // Zoom - Markers
    document.querySelectorAll('.marker').forEach((marker) => {
      marker.style.transform = `scale(${markersZoom})`;
    });
  } else {
    // Zoom - Map
    document.querySelector(
      '.map__wrapper'
    ).style.transform = `scale(${mapZoom})`;
    // Zoom - Markers
    document.querySelectorAll('.marker').forEach((marker) => {
      marker.style.transform = `scale(${markersZoom})`;
    });
  }
}

export function setMarkersScale(markersZoom = 1) {
  // Zoom - Markers
  document.querySelectorAll('.marker').forEach((marker) => {
    marker.style.transform = `scale(${markersZoom})`;
  });
}

// Get the markers of the same country and add them a class to change their position
export function showMoreMarkers(element) {
  document.querySelectorAll('.marker').forEach((marker) => {
    if (marker.dataset.country === element.dataset.country) {
      marker.classList.add(MOVE_MARKER_CLASS);
      marker.dataset.moreThanOne = false;
      marker.dataset.selected = true;
      marker.dataset.showOtherMarkers = true;
    }
  });
}

export function resetMarkerOptions() {
  document.querySelectorAll('.marker').forEach((marker) => {
    if (marker.hasAttribute('data-selected')) {
      marker.dataset.moreThanOne = true;
      marker.dataset.showOtherMarkers = false;
      // Remove move-markers class (It is added when there is more than one project per country)
      marker.classList.remove(MOVE_MARKER_CLASS);
    }
  });
}

export function countProyectsPerCountry(translation, data, fn) {
  let countries = translation.data.countries;
  let countCountries = {};

  countries.forEach((country) => {
    countCountries[country] = translation.data.projects.reduce(
      (number, project) => {
        if (data.category === 'all') {
          return project.country === country ? number + 1 : number;
        } else {
          if (
            project.country === country &&
            project.category === data.category
          ) {
            return number + 1;
          } else {
            return number;
          }
        }
      },
      0
    );
  });

  fn({ ...data, numberProjectsByCountry: countCountries });
}

export function getCountryPosition(country) {
  let svgMap = document.querySelector('.map').getBoundingClientRect();
  let mapWrapper = document
    .querySelector('.map__wrapper')
    .getBoundingClientRect();
  let position = {};
  let svgCountryPosition = document
    .querySelector(`[data-target][data-country="${country}"]`)
    .getBoundingClientRect();

  position.x =
    Math.abs(svgCountryPosition.x - mapWrapper.x) +
    svgCountryPosition.width / 2;
  position.y =
    Math.abs(svgCountryPosition.y - mapWrapper.y) +
    svgCountryPosition.height / 2;

  // position.x = svgCountryPosition.x + svgCountryPosition.width / 2 - svgMap.x;
  // position.y = svgCountryPosition.y + svgCountryPosition.height / 2 - mapWrapper.y;
  return position;
}
