import React from 'react';
import CategoryList from '../CategoryList';
import MoreProjects from '../MoreProjects';

import './styles.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <CategoryList />
        <MoreProjects />
      </div>
    </footer>
  );
}

export default Footer;
