import React, { useContext } from 'react';
import { TranslationContext } from '../../context/TranslationContext';
import { DataContext } from '../../context/DataContext';

import DE from '../../translations/de/global.json';
import EN from '../../translations/en/global.json';

import './styles.scss';

function LanguageSwitcher() {
  const [translation, setTranslation] = useContext(TranslationContext);
  const [data, setData] = useContext(DataContext);
  const isActiveClass = data.project ? '' : ' language-switcher--active';
  if (!translation.data.onlyGerman)
    return (
      <div className={`language-switcher${isActiveClass}`}>
        <button
          className="language-switcher__button"
          onClick={() => setTranslation({ language: 'de', data: DE })}
        >
          DE
        </button>
        {!translation.data.onlyGerman && (
          <>
            <span className="language-switcher__separator"> | </span>
            <button
              className="language-switcher__button"
              onClick={() => setTranslation({ language: 'en', data: EN })}
            >
              EN
            </button>
          </>
        )}
      </div>
    );
}

export default LanguageSwitcher;
