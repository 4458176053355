import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Intro from '../../components/Intro';
import Map from '../../components/Map';
import ProjectDetail from '../../components/ProjectDetail';
import ProjectList from '../../components/ProjectList';
import Zoom from '../../components/Zoom';

function Below() {
  return (
    <>
      <Header />
      <Intro />
      <ProjectList />
      <ProjectDetail />
      <Map />     
      <Footer />
    </>
  );
}

export default Below;
