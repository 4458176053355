import React, { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import { MapContext } from '../../context/MapContext';
import { useChannel } from '../../hooks/useChannel';
import { moveMap, resetMarkerOptions } from '../../utils/moveMap';

import './styles.scss';

function CategoryItem(props) {
  const channel = useChannel();
  const { id, name, isActive } = props;
  const [data, setData] = useContext(DataContext);
  const [map, setMap] = useContext(MapContext);
  const isActiveClass = isActive ? ' category-item--active' : '';

  function handleClick(e) {
    if (e.target.getAttribute('id') === data.category) {
      channel.sendMessage({
        ...data,
        category: 'all',
        isAppActive: true,
        
      });
    } else {
      channel.sendMessage({
        ...data,
        category: e.target.getAttribute('id'),
        isAppActive: true,
        
      });
    }
    setMap({
      ...map,
      step: 0,
      isActivatedZoomButton: false,
      event: undefined,
      markerCountry: '',
    });
    moveMap(undefined, map.zoomSteps[0].map, map.zoomSteps[0].marker);
    resetMarkerOptions();
  }

  return (
    <li
      id={id}
      className={`category-item${isActiveClass}`}
      onClick={handleClick}
    >
      <p className="category-item__name">{name}</p>
    </li>
  );
}

export default CategoryItem;
