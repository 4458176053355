import { useState } from 'react';

export function useVidconChannel() {
  const channel = new BroadcastChannel('tui-vc-channel');
  const [channelEvents, setChannelEvents] = useState({
    getMessage: (setVariable) => {
      channel.onmessage = (msg) => {
        setVariable(msg.data);
      };
    },
    sendMessage: (message) => {
      channel.postMessage(message);
    },
  });
  return channelEvents;
}
