import React, { useState, createContext } from 'react';

export const MapContext = createContext();

export function MapProvider(props) {
  const [map, setMap] = useState({
    event: undefined,
    isActivatedZoomButton: false,
    step: 0,
    zoomSteps: [
      {
        map: 1,
        marker: 1,
      },
      {
        map: 2,
        marker: 0.5,
      },
      {
        map: 3,
        marker: 0.4,
      },
      {
        map: 5,
        marker: 0.3,
      },
      {
        map: 7,
        marker: 0.2,
      },
      // {
      //   map: 9,
      //   marker: 0.25,
      // },
    ],
    markerCountry: '',
  });

  return (
    <MapContext.Provider value={[map, setMap]}>
      {props.children}
    </MapContext.Provider>
  );
}
