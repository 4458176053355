import React, { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import { TranslationContext } from '../../context/TranslationContext';

import './styles.scss';

function Intro() {
  const [translation, setTranslation] = useContext(TranslationContext);
  const [data, setData] = useContext(DataContext);
  const isActiveClass = data.project ? '' : ' intro--active';

  return (
    <div className={`intro${isActiveClass}`}>
      <h1 className="intro__title">{translation.data.introTitle}</h1>
      <p className="intro__description">{translation.data.introDescription}</p>
    </div>
  );
}

export default Intro;
