import React, { useContext, useEffect, useState } from 'react';

import { DataContext } from '../../context/DataContext';
import { TranslationContext } from '../../context/TranslationContext';
import { MapContext } from '../../context/MapContext';
import ProjectDetailSlider from '../ProjectDetailSlider';

import './styles.scss';
import { moveMap } from '../../utils/moveMap';

function ProjectDetail() {
  const [data, setData] = useContext(DataContext);
  const [map, setMap] = useContext(MapContext);
  const [translation, setTranslation] = useContext(TranslationContext);
  const [hide, setHide] = useState(false);
  let project = [];

  useEffect(() => {
    setHide(false);
  }, [data]);

  function handleClick() {
    setHide(true);
  }

  project = translation.data.projects.filter(
    (project) => project.id === data.detailProject
  );

  if (project.length === 0 || hide) {
    return;
  }

  return (
    <section className="project-detail">
      <div className="project-detail__wrapper">
        <h2 className="project-detail__title">{project[0].name}</h2>
        <div className="project-detail__slider">
          <ProjectDetailSlider project={project[0]} />
        </div>
        <p className="project-detail__description">
          {translation.data.projectDetailText}
        </p>
        <button className="project-detail__close-btn" onClick={handleClick} />
      </div>
    </section>
  );
}

export default ProjectDetail;
