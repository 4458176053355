import React, { useContext, useEffect, useState } from 'react';
import { TranslationContext } from '../../context/TranslationContext';
import { DataContext } from '../../context/DataContext';
import { useChannel } from '../../hooks/useChannel';
import ProjectItem from '../ProjectItem';

import './styles.scss';

function ProjectList() {
  const channel = useChannel();
  const [data, setData] = useContext(DataContext);
  const [translation, setTranslation] = useContext(TranslationContext);
  const [selectedProject, setSelectedProject] = useState([]);
  let projectsByLand = [];
  let projects = [];
  let targetProject = [];

  useEffect(() => {
    setSelectedProject(
      translation.data.projects.filter((project) => project.id === data.project)
    );
  }, [data, translation]);

  function handleClick(e) {
    targetProject = translation.data.projects.filter(
      (project) => project.id === e.target.dataset.project
    );
    channel.sendMessage({
      ...data,
      detailProject: e.target.dataset.project,
      mediaName: targetProject[0]?.thumbnail,
      isAppActive: true,
      isVideoPlaying: false,
    });
  }

  if (selectedProject && selectedProject.length > 0) {
    // Return all projects with the same land and selected category (and exclude the selected project)
    projectsByLand = translation.data.projects.filter((project) => {
      if (data.category === 'all') {
        if (
          project.land === selectedProject[0]?.land &&
          project.id !== selectedProject[0]?.id
        ) {
          return project;
        }
      } else {
        if (
          project.land === selectedProject[0]?.land &&
          project.id !== selectedProject[0]?.id &&
          project.category === data.categoryMarker
        ) {
          return project;
        }
      }
    });

    // Add selected project to the beginning
    projectsByLand.unshift(selectedProject[0]);

    projects = projectsByLand.map((project) => (
      <ProjectItem
        key={project.id}
        id={project.id}
        name={project.name}
        thumbnail={project?.thumbnail}
        handleClick={handleClick}
        isSelected={project.id === data.detailProject ? true : false}
      />
    ));
  }

  if (projects && projects.length === 0) {
    return;
  }

  return (
    <section className="project-list">
      <div className="project-list__wrapper">
        <h1 className="project-list__title">{selectedProject[0]?.land}</h1>
        <div className="project-list__projects">{projects}</div>
      </div>
    </section>
  );
}

export default ProjectList;
