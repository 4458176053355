import React, { useState, createContext } from 'react';

import DE from '../translations/de/global.json';

export const TranslationContext = createContext();

export function TranslationProvider(props) {
  // Initial language: German
  const [translation, setTranslation] = useState({
    language: 'de',
    data: DE,
  });

  return (
    <TranslationContext.Provider value={[translation, setTranslation]}>
      {props.children}
    </TranslationContext.Provider>
  );
}
