import React, { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import { MapContext } from '../../context/MapContext';
import { TranslationContext } from '../../context/TranslationContext';
import { useChannel } from '../../hooks/useChannel';
import { moveMap, resetMarkerOptions } from '../../utils/moveMap';

import './styles.scss';

function GoBackButton() {
  const channel = useChannel();
  const [data, setData] = useContext(DataContext);
  const [translation, setTranslation] = useContext(TranslationContext);
  const [map, setMap] = useContext(MapContext);

  const isActiveClass =
    data.project || data.showBackBtn ? ' go-back-button--active' : '';

  function handleClick() {
    setMap({
      ...map,
      step: 0,
      isActivatedZoomButton: false,
      event: undefined,
      markerCountry: '',
    });
    moveMap(undefined, map.zoomSteps[0].map, map.zoomSteps[0].marker);
    resetMarkerOptions();
    channel.sendMessage({
      ...data,
      project: '',
      detailProject: '',
      category: 'all',
      isAppActive: false,
      showBackBtn: false,
      isVideoPlaying: false,
    });
  }

  return (
    <button className={`go-back-button${isActiveClass}`} onClick={handleClick}>
      {translation.data.goBackButton}
    </button>
  );
}

export default GoBackButton;
