import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as MapSVG } from '../../assets/map/map.svg';
import { DataContext } from '../../context/DataContext';
import { TranslationContext } from '../../context/TranslationContext';
import { MapContext } from '../../context/MapContext';
import Marker from '../Marker';

import { useChannel } from '../../hooks/useChannel';
import {
  countProyectsPerCountry,
  moveMap,
  setMarkersScale,
  showMoreMarkers,
} from '../../utils/moveMap';

import './styles.scss';

function Map() {
  const [data, setData] = useContext(DataContext);
  const [translation, setTranslation] = useContext(TranslationContext);
  const [map, setMap] = useContext(MapContext);
  const [projects, setProjects] = useState([]);
  const channel = useChannel();
  let markers = [];
  let timer2;
  let targetProject = [];
  let numbers = 0;

  useEffect(() => {
    if (data.category === 'all') {
      setProjects(translation.data.projects);
      countProyectsPerCountry(translation, data, setData);
    } else {
      setProjects(
        translation.data.projects.filter(
          (project) => project.category === data.category
        )
      );
      countProyectsPerCountry(translation, data, setData);
    }

    const timer = setTimeout(() => {
      setMarkersScale(map.zoomSteps[map.step].marker);
    }, 0);
    return () => clearTimeout(timer);
  }, [data.category, data.project, translation]);

  function handleClick(e) {
    // If there is more than one project in the same country
    if (e.target.dataset.moreThanOne === 'true') {
      showMoreMarkers(e.target);
      channel.sendMessage({
        ...data,
        mediaName: targetProject[0]?.thumbnail,
        isAppActive: true,
        showBackBtn: true,
        isVideoPlaying: false,
      });
      // There is an event if you click on a marker
      if (e) {
        moveMap(e, map.zoomSteps[1].map, map.zoomSteps[1].marker);
        setMap({
          ...map,
          event: e,
          step: 1,
          markersZoom: map.zoomSteps[1].marker,
          markerCountry: e.target.dataset.country,
        });
      }
    } else {
      targetProject = translation.data.projects.filter(
        (project) => project.id === e.target.dataset.project
      );

      channel.sendMessage({
        ...data,
        mediaName: targetProject[0]?.thumbnail,
        isAppActive: true,
        isVideoPlaying: false,
      });

      // Timer ->When the map finishes moving to the marker, show the other components
      if (timer2) {
        clearTimeout(timer2);
      }
      timer2 = setTimeout(() => {
        channel.sendMessage({
          ...data,
          mediaName: targetProject[0]?.thumbnail,
          project: e.target.dataset.project,
          detailProject: e.target.dataset.project,
          categoryMarker: e.target.dataset.category,
          isAppActive: true,
          isVideoPlaying: false,
        });
      }, 750);

      // There is an event if you click on a marker
      if (e) {
        if (map.isActivatedZoomButton) {
          moveMap(
            e,
            map.zoomSteps[map.step].map,
            map.zoomSteps[map.step].marker
          );
          setMap({
            ...map,
            event: e,
            step: map.step,
            markersZoom: map.zoomSteps[map.step].marker,
          });
        } else {
          moveMap(e, map.zoomSteps[1].map, map.zoomSteps[1].marker);
          setMap({
            ...map,
            event: e,
            step: 1,
            markersZoom: map.zoomSteps[1].marker,
          });
        }
      }
    }
  }

  markers = projects.map((item) => {
    let positionProject = {};
    let defaultPosition = {};

    if (data.numberProjectsByCountry[item.country] > 1) {
      numbers = data.numberProjectsByCountry[item.country];
    } else {
      numbers = '';
    }
    if (item.country) {
      if (item.position) {
        if (item.position.x) {
          positionProject.x = item.position.x;
        }
        if (item.position.y) {
          positionProject.y = item.position.y;
        }
      }

      if (translation.data.defaultMarkersPosition[item.country]) {
        if (translation.data.defaultMarkersPosition[item.country].x) {
          defaultPosition.x =
            translation.data.defaultMarkersPosition[item.country].x;
        }
        if (translation.data.defaultMarkersPosition[item.country].y) {
          defaultPosition.y =
            translation.data.defaultMarkersPosition[item.country].y;
        }
      }
    }

    return (
      <Marker
        key={`m-${item.id}`}
        id={`m-${item.id}`}
        categoryId={item.category}
        projectId={item.id}
        projectLand={item.land}
        projectCountry={item.country}
        projectNumbers={numbers}
        projectThumbnail={item?.markerImage ? item.thumbnail : ''}
        handleClick={handleClick}
        defaultPosition={
          translation.data.defaultMarkersPosition[item.country]
            ? defaultPosition
            : ''
        }
        positionProject={item.position ? positionProject : ''}
      />
    );
  });

  return (
    <div className="map">
      <div className="map__wrapper">
        <MapSVG />
        {markers}
      </div>
    </div>
  );
}

export default Map;
